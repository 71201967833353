import * as R from "ramda";
import f from "lib/util";

import PropTypes from 'prop-types';

export const VALIDATION_RESULT_PROPTYPE = PropTypes.shape({
    valid: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string
});

/**
 * Returns a function that validates a object by given rules.
 * Example:
 * <pre>
 *     const validate = createValidator({
 *         name: name => !!name,
 *         email: email => email.indexOf('@') > 0
 *     });
 *     const validationResult = validate({
 *         name: undefined,
 *         email: 'kevin@sobr.ch'
 *     });
 *
 *     validationResult === {
 *         name: {valid: false, errorMsg: 'did not pass validation rule'},
 *         email: {valid: true, errorMsg: null}
 *     }
 * </pre>
 */
export function createValidator(rules) {
    return data => {
        if (!data) {
            throw new Error("no data for validation supplied");
        }

        return R.pipe(
            R.toPairs,
            R.map(R.tryCatch(
                ([key, fn]) => ([key, fn(data[key], data)]),
                (e, [key]) => [key, `validation rule threw an error: ${e}`]
            )),
            R.fromPairs,
            R.map(validatorReturnValueToValidationResult)
        )(rules);
    }
}

/**
 * converts a return value of a validation function to an object of form
 * <pre>
 *     {
 *         valid: true | false,
 *         errorMsg: error message
 *     }
 * </pre>
 */
const validatorReturnValueToValidationResult = R.cond([
    [R.equals(true),  R.always({valid: true, errorMsg: null})],
    [R.equals(false), R.always({valid: false, errorMsg: 'did not pass validation rule'})],
    [R.is(String),    val => ({valid: false, errorMsg: val})]
]);

/**
 * creates a function that calls validator on a value and returns a boolean value whether that value is valid or not.
 * (validators can return strings to indicate invalid values)
 */
function validatorReturnsValid(validator) {
    return (value, dataObject) => validator(value, dataObject) === true;
}

const validatorResultIsValid = R.compose(R.equals(true), R.prop('valid'), validatorReturnValueToValidationResult);

export const isValid = R.pipe(
    R.toPairs,
    R.map(R.path([1, 'valid'])),
    f.listAnd
);

export const withMessage = (msg, validator) => R.ifElse(validatorReturnsValid(validator), R.T, R.always(msg));
export const notEmpty = R.complement(R.isEmpty);

export const maxLength = l => R.compose(R.lte(R.__, l), R.length);
export const minLength = l => R.compose(R.gte(R.__, l), R.length);
export const lengthRange = (min, max) => withMessage(`muss zwischen ${min} und ${max} zeichen haben`, R.allPass([minLength(min), maxLength(max)]));

export function requiredIf(cond) {
    return (value) => cond ? !!value : !value;
}

export const matchesRegex = R.test;
export const validPassword = withMessage("Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Grossbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.", matchesRegex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,255}$/));
export const isEmail = withMessage("muss eine gültige email adresse sein", matchesRegex(/^[a-z0-9+._-]+@[a-z0-9_-]+\.[a-z]{1,20}$/i));
export const isPhoneNumber = withMessage("muss eine gültige telefon nummer sein", matchesRegex(/^(\+|0{1,2})[0-9]{4,20}$/i));
export const isIban = withMessage("muss eine gültige IBAN sein", R.pipe(f.removeWhitespace, R.toUpper, matchesRegex(/^[A-Z]{2}[0-9]{18,30}[A-Z]?$/)));
export const isBic = withMessage("muss eine gültige BIC sein", matchesRegex(/^[A-Z0-9]{8,11}$/));
export const optionalStandardTextField = optional(lengthRange(1, 255));
export const requiredStandardTextField = required(lengthRange(1, 255));

export function optional(validator) {
    return or([withMessage("Kann leer sein", f.isNilOrEmpty), validator])
}

export function required(validator) {
    return and([
        withMessage("Ist Pflichtfeld", f.isNotNilOrEmpty),
        validator
    ]);
}

export function containsAllChars(charList) {
    return value => {
        R.pipe(
            R.map(char => value.indexOf(char) > 0),
            R.allPass
        )(charList.split(''))
    };
}

export function containsAnyChar(charList) {
    return value => {
        R.pipe(
            R.map(char => value.indexOf(char) > 0),
            R.anyPass
        )(charList.split(''))
    };
}

/**
 * Logical and for a list of validators.
 * Returns the first non-valid validation result.
 */
export function and(validatorList) {
    if (R.isEmpty(validatorList)) {
        return R.always("Invalid validator: 'and' must contain at least one rule");
    }
    return (value, dataObject) => {
        return R.pipe(
            R.map(validator => validator(value, dataObject)),
            R.find(R.complement(validatorResultIsValid)),
            R.defaultTo(true)
        )(validatorList);
    }
}

export function or(validatorList) {
    if (R.isEmpty(validatorList)) {
        return R.always("Invalid validator: 'or' must contain at least one rule");
    }
    return (value, dataObject) => {
        const validatorResults = validatorList.map(validator => validator(value, dataObject));
        return R.pipe(
            R.find(validatorResultIsValid),
            R.defaultTo(R.join(' oder ', validatorResults))
        )(validatorResults);
    }
}

export default {
    VALIDATION_RESULT_PROPTYPE,
    createValidator,
    isValid,
    withMessage,
    notEmpty,
    minLength,
    maxLength,
    lengthRange,
    requiredIf,
    matchesRegex,
    validPassword,
    isEmail,
    isPhoneNumber,
    isIban,
    isBic,
    optional,
    required,
    optionalStandardTextField,
    requiredStandardTextField,
    containsAllChars,
    containsAnyChar,
    and,
    or
}
