import {RegistrationFormListActions} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormList/registrationFormListSlice";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import SobrTextInput from "components/SobrForm/Inputs/SobrTextInput";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'moment';
import {FormattedMessage} from "react-intl";
import {UserTypeEnum} from "services/users/UserService";
import RegistrationFormService from "services/registrationforms/RegistrationFormService";
import {compose} from "redux";
import {SobrFormGroup} from "components/SobrForm/SobrFormGroup";
import {SobrText} from "components/SobrForm/Forms/sobrText";
import {SobrSelect as SobrLegacySelect} from "components/SobrForm/Forms/sobrSelect";
import {SobrSingleSelect} from "components/SobrForm/Inputs/SobrSelect"
import {SobrDate} from "components/SobrForm/Forms/sobrDate";
import {SobrZipCity} from "components/SobrForm/Forms/sobrZipCity";
import {SobrCustomCheckbox} from "components/SobrForm/Inputs/SobrCustomCheckbox";
import {SobrTextarea} from "components/SobrForm/Inputs/SobrTextarea";
import DialogComponent from 'components/DialogComponent';
import CustomToastContainer from "components/CustomToastContainer";
import $ from "jquery";
import {Link, withRouter} from "react-router-dom";
import {API_ROOT} from "api-config";
import {Actions} from "./redux";
import {toast} from "react-toastify";
import {SobrFormRow} from "components/SobrForm/Forms/SobrFormRow";
import * as R from 'ramda';
import SobrPopup from "components/SobrPopup/SobrPopup";
import {faPlus, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isNil} from 'ramda';
import {injectIntl} from "react-intl";


export class RegistrationFormComponent extends React.Component {

    static propTypes = {
        userType: PropTypes.string.isRequired,
        create: PropTypes.bool,
        edit: PropTypes.bool,
        read: PropTypes.bool,
        currentRegistrationForm: PropTypes.any,
        nationalityData: PropTypes.any,
        interestsData: PropTypes.any,
        languageData: PropTypes.any,
        idCardData: PropTypes.any,
        taxCategoryData: PropTypes.any,
        taxExemptReasonData: PropTypes.any,
        currentServicePartner: PropTypes.any,
        createRegForm: PropTypes.func,
        updateRegistrationForm: PropTypes.func,
        deleteGuest: PropTypes.func,
        deleteRegForm: PropTypes.func,
        addGroup: PropTypes.func,
        updateGuestDataRegForm: PropTypes.func,
        addExclusion: PropTypes.func,
        updateExclusion: PropTypes.func,
        deleteExclusion: PropTypes.func,
        addGuest: PropTypes.func,

        deleteGuestPopupVisible: PropTypes.bool,
        setDeleteGuestPopupVisible: PropTypes.func
    };

        constructor(props) {
        super(props);

        this.state = {
            priv_company: '',
            priv_salutation: '',
            priv_title: '',
            priv_firstName: '',
            priv_lastName: '',
            priv_middleName: '',
            priv_birthday: null,
            priv_nationality: null,
            priv_language: null,
            priv_interest: null,
            contact_email: '',
            contact_phoneNumber: '',
            contact_mobileNumber: '',
            contact_street: '',
            contact_zip: '',
            contact_city: '',
            contact_country: null,
            contact_post: '',
            pass_number: '',
            pass_checkinDate: null,
            pass_checkoutDate: null,
            pass_idCard: null,
            tax_free: false,
            tax_reason: null,
            tax_comment: '',
            apartment: '',
            tax_category: null,
            guestList: null,
            selectedGuest: null,
            anonymousGuest: false,
            isNewGuest: false,
            /* Error States */
            priv_salutationError: false,
            priv_firstNameError: false,
            priv_lastNameError: false,
            priv_nationalityError: false,
            priv_languageError: false,
            priv_birthdayError: false,
            contact_mobileNumberError: false,
            contact_mobileNumberFormatError: false,
            contact_emailError: false,
            contact_emailFormatError: false,
            contact_streetError: false,
            contact_zipError: false,
            contact_cityError: false,
            contact_countryError: false,
            tax_reasonError: false,
            checkin_date_error: false,
            exempt_child_birthday_error: false,
            sendPostEmail: this.props.currentRegistrationForm ? this.props.currentRegistrationForm.sendPostEmail : true,
            bookedPackage: this.props.currentRegistrationForm ? this.props.currentRegistrationForm.bookedPackage : false
        };

        this.primaryGuestId = '';

        this.isServicePartner = this.isServicePartner.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onChangeNationality = this.onChangeNationality.bind(this);
        this.onChangeLanguageCode = this.onChangeLanguageCode.bind(this);
        this.onChangeInterests = this.onChangeInterests.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeDocumentType = this.onChangeDocumentType.bind(this);
        this.onChangeCheckinDate = this.onChangeCheckinDate.bind(this);
        this.onChangeCheckoutDate = this.onChangeCheckoutDate.bind(this);
        this.onChangeTaxReason = this.onChangeTaxReason.bind(this);
        this.updateState = this.updateState.bind(this);
        this.loadGuestById = this.loadGuestById.bind(this);
        this.onChangeTaxCategory = this.onChangeTaxCategory.bind(this);
        this.validate = this.validate.bind(this);
        this.printPdf = this.printPdf.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.is_valid_text = this.is_valid_text.bind(this);
        this.is_valid_mobileNumber = this.is_valid_mobileNumber.bind(this);
        this.is_valid_select = this.is_valid_select.bind(this);
        this.setAnonymousState = this.setAnonymousState.bind(this);
        this.deleteGuest = this.deleteGuest.bind(this);
        this.resize = this.resize.bind(this);
        this.deleteRegForm = this.deleteRegForm.bind(this);
        this.duplicate = this.duplicate.bind(this);
        this.addNewGuestState = this.addNewGuestState.bind(this);
        this.groupAdd = this.groupAdd.bind(this);
        this.clearForms = this.clearForms.bind(this);
        this.getInterestIds = this.getInterestIds.bind(this);
        this.addExclusion = this.addExclusion.bind(this);
        this.updateExclusion = this.updateExclusion.bind(this);
        this.deleteExclusion = this.deleteExclusion.bind(this);

        this.showDeleteGuestPopup = this.showDeleteGuestPopup.bind(this);
        this.hideDeleteGuestPopup = this.hideDeleteGuestPopup.bind(this);
    }

    static defaultProps = {
        create: false,
        read: false,
        edit: false
    };

    get isPolice() {
        return this.props.userType === UserTypeEnum.POLICE;
    }

    isServicePartner() {
        return this.props.userType === UserTypeEnum.SERVICE_PARTNER;
    }

    componentDidMount() {
        if (!this.props.create) {
            const guests = this.props.currentRegistrationForm.guests;
            const firstGuest = guests.find(guest => guest.primary === true) || guests[0];
            if (!isNil(firstGuest)) {
                this.primaryGuestId = firstGuest.id;
                this.loadGuestById(this.primaryGuestId);
            }
        } else {
            const today = new Moment.utc();
            this.setState({
                pass_checkinDate: {key: 'checkinDate', value: today},
                priv_birthday: {key: 'birthday', value: null},
                pass_checkoutDate: {key: 'checkoutDate', value: Moment.utc(today).add('days', 1)},
                isNewGuest: true
            }, () => {
                this.resize();
            });
        }
    }

    resize() {
        let content = $('#registration-form-content');
        if (content.length > 0) {
            let inner = content.find('.content-wrap');
            let contentHeight = content.height();
            let innerHeight = inner.height();
            let offsetTop = content.offset().top;
            let windowHeight = $(window).height();
            let sizeTo100Percent = windowHeight - offsetTop;
            let newInnerHeight = sizeTo100Percent - (contentHeight - innerHeight);

            inner.css('height', newInnerHeight + 'px');
        }
    }

    loadGuestById(guestId) {
        const registrationForm = this.props.currentRegistrationForm;
        const guestData = registrationForm.guests.find(guest => guest.id === guestId);


        this.setState({
            selectedGuest: guestData,
            guestList: registrationForm.guests,
            priv_company: guestData.guest.company ? guestData.guest.company : '',
            priv_salutation: guestData.guest.salutation ? guestData.guest.salutation : '',
            priv_title: guestData.guest.title ? guestData.guest.title : '',
            priv_firstName: guestData.guest.firstName ? guestData.guest.firstName : '',
            priv_middleName: guestData.guest.middleName ? guestData.guest.middleName : '',
            priv_lastName: guestData.guest.lastName ? guestData.guest.lastName : '',
            priv_birthday: guestData.guest.birthday ? {
                key: 'birthday',
                value: Moment.utc(guestData.guest.birthday ? guestData.guest?.birthday : null)
            } : null,
            priv_nationality: guestData.guest.nationality ? guestData.guest.nationality : null,
            priv_language: guestData.guest.languageCode ? guestData.guest.languageCode : '',
            priv_interest: guestData.guest.interests ? guestData.guest.interests : '',
            contact_email: guestData.guest.email ? guestData.guest.email : '',
            contact_phoneNumber: guestData.guest.phoneNumber ? guestData.guest.phoneNumber : '',
            contact_mobileNumber: guestData.guest.mobileNumber ? guestData.guest.mobileNumber : '',
            contact_street: guestData.guest.address && guestData.guest.address.street ? guestData.guest.address.street : '',
            contact_zip: guestData.guest.address && guestData.guest.address.zip ? guestData.guest.address.zip : '',
            contact_city: guestData.guest.address && guestData.guest.address.city ? guestData.guest.address.city : '',
            contact_country: guestData.guest.address && guestData.guest.address.country ? guestData.guest.address.country : '',
            contact_post: guestData.guest.address && guestData.guest.address.poBox ? guestData.guest.address.poBox : '',
            pass_number: guestData.guest.identificationDocument && guestData.guest.identificationDocument.number ? guestData.guest.identificationDocument.number : '',
            pass_idCard: guestData.guest.identificationDocument && guestData.guest.identificationDocument.documentType && guestData.guest.identificationDocument.documentType.value ? guestData.guest.identificationDocument.documentType.value : '',
            pass_checkinDate: {key: 'checkinDate', value: Moment.utc(guestData.checkinDate)},
            pass_checkoutDate: {key: 'checkoutDate', value: Moment.utc(guestData.checkoutDate)},
            tax_free: guestData.touristTaxExempt ? guestData.touristTaxExempt : false,
            tax_reason: guestData.taxExemptReason && guestData.taxExemptReason.value ? guestData.taxExemptReason.value : null,
            tax_comment: guestData.comment ? guestData.comment : '',
            apartment: guestData.apartment ?? '',
            tax_category: guestData.taxCategory && guestData.taxCategory.value ? guestData.taxCategory.value : '',
            anonymousGuest: guestData.guest.anonymous ? guestData.guest.anonymous : false,
            isNewGuest: false,
            /* Error States */
            priv_salutationError: false,
            priv_firstNameError: false,
            priv_lastNameError: false,
            priv_nationalityError: false,
            priv_languageError: false,
            priv_birthdayError: false,
            contact_mobileNumberError: false,
            contact_mobileNumberFormatError: false,
            contact_emailError: false,
            contact_emailFormatError: false,
            contact_streetError: false,
            contact_zipError: false,
            contact_cityError: false,
            contact_countryError: false,
            tax_reasonError: false
        }, () => {
            this.resize();
        });
    }

    clearForms(cb) {
        this.setState({
            priv_company: '',
            priv_salutation: '',
            priv_salutationError: false,
            priv_title: '',
            priv_firstName: '',
            priv_firstNameError: false,
            priv_lastName: '',
            priv_lastNameError: false,
            priv_middleName: '',
            priv_birthday: null,
            priv_birthdayError: false,
            priv_nationality: null,
            priv_nationalityError: false,
            priv_language: null,
            priv_languageError: false,
            priv_interest: null,
            contact_email: '',
            contact_emailError: false,
            contact_emailFormatError: false,
            contact_phoneNumber: '',
            contact_mobileNumber: '',
            contact_mobileNumberError: false,
            contact_mobileNumberFormatError: false,
            contact_street: '',
            contact_streetError: false,
            contact_zip: '',
            contact_zipError: false,
            contact_city: '',
            contact_cityError: false,
            contact_country: null,
            contact_countryError: false,
            contact_post: '',
            pass_number: '',
            //pass_checkinDate: {key: 'checkinDate', value: null},
            //pass_checkoutDate: {key: 'checkoutDate', value: null},
            pass_idCard: null,
            tax_free: false,
            tax_reason: null,
            tax_reasonError: false,
            tax_comment: '',
            apartment: '',
            tax_category: null,
            selectedGuest: null,
            anonymousGuest: false,
            isNewGuest: false
        }, () => {
            if (cb !== undefined)
                cb();
        });
    }

    updateState(key) {
        return val => {
            this.setState({[key]: val})
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    onChangeBirthday(e) {
        this.setState({
            priv_birthday: {
                key: 'birthday',
                value: e
            }
        });
    }

    onChangeNationality(e) {
        this.setState({
            priv_nationality: e.value
        });
    }

    onChangeLanguageCode(e) {
        this.setState({
            priv_language: e.value
        });
    }

    onChangeInterests(e) {
        this.setState({
            priv_interest: e
        });
    }

    onChangeCountry(e) {
        this.setState({
            contact_country: e.value
        });
    }

    onChangeDocumentType(e) {
        this.setState({
            pass_idCard: e.value
        });
    }

    onChangeCheckinDate(e) {
        this.setState({
            pass_checkinDate: {
                key: 'checkinDate',
                value: e
            }
        });
    }

    onChangeCheckoutDate(e) {
        this.setState({
            pass_checkoutDate: {
                key: 'checkoutDate',
                value: e
            }
        });
    }

    onChangeTaxReason(e) {
        this.setState({
            tax_reason: e.value
        });
    }

    onChangeTaxCategory(e) {
        this.setState({
            tax_category: e.value
        });
    }

    is_valid_text(value, isEmail = false) {
        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (isEmail)
            return emailPattern.test(String(value).toLowerCase());
        else
            return value && value.length > 0;
    }

    is_valid_small_child(value) {
        if (!value) {
            return false;
        }

        const today = Moment.utc()

        const yearsDiff = today.diff(value, 'years', false); // Calculate the difference in years
        return yearsDiff < 9;
    }


    is_valid_mobileNumber(value) {
        const mobileNumberPattern = /^\+?[\d\s]+$/;

        return mobileNumberPattern.test(String(value).toLowerCase());
    }

    is_valid_select(value) {
        return value !== null && !R.isEmpty(value);
    }

    get isReadOnly() {
        return !this.props.edit && !this.props.create && !this.props.read && !this.isPolice;
    }

    get canDuplicate() {
        return !this.isReadOnly;
    }

    get canDeleteCurrentGuest() {
        return !this.isReadOnly && this.state.selectedGuest && !this.state.selectedGuest.primary;
    }

    get canSaveCurrentGuest() {
        return !this.isReadOnly;
    }

    get canAnonymizeCurrentGuest() {
        return !this.isReadOnly && this.state.selectedGuest && !this.state.selectedGuest.primary && this.state.selectedGuest.guest.anonymous && !this.state.anonymousGuest;
    }

    get canDeanonymizeCurrentGuest() {
        return !this.isReadOnly && this.state.selectedGuest && !this.state.selectedGuest.primary && this.state.selectedGuest.guest.anonymous && this.state.anonymousGuest;
    }

    get showBackButton() {
        return this.state.selectedGuest && this.state.selectedGuest.primary && !this.isPolice;
    }

    validate() {

        let simpleRegistrationForm = this.props.currentServicePartner.simpleRegistrationForm;

        let isPrimaryGuest = (this.state.selectedGuest && this.state.selectedGuest.id === this.primaryGuestId) || this.props.create;

        let firstName = this.is_valid_text(this.state.priv_firstName);
        let lastName = this.is_valid_text(this.state.priv_lastName);
        let nationality = this.is_valid_select(this.state.priv_nationality);
        let language = this.is_valid_select(this.state.priv_language);
        let birthday = this.is_valid_select(this.state.priv_birthday?.value ?? null);

        let emailFormat = isPrimaryGuest || this.state.contact_email ? this.is_valid_text(this.state.contact_email, true) : true;

        let email = isPrimaryGuest ? this.is_valid_text(this.state.contact_email) : true;
        let street = isPrimaryGuest ? this.is_valid_text(this.state.contact_street) : true;
        let zip = isPrimaryGuest ? this.is_valid_text(this.state.contact_zip) : true;
        let city = isPrimaryGuest ? this.is_valid_text(this.state.contact_city) : true;
        let salutation = isPrimaryGuest ? this.is_valid_text(this.state.priv_salutation) : true;

        let country = isPrimaryGuest ? this.is_valid_select(this.state.contact_country) : true;
        let taxReason = this.state.tax_free ? this.is_valid_select(this.state.tax_reason) : true;
        let mobileNumber = isPrimaryGuest ? this.is_valid_text(this.state.contact_mobileNumber) : true;
        let mobileNumberFormat = isPrimaryGuest || this.state.contact_mobileNumber ? this.is_valid_mobileNumber(this.state.contact_mobileNumber) : true;
        let checkinDate = this.state.pass_checkinDate.value.startOf('day') <= this.state.pass_checkoutDate.value.startOf('day');
        let exemptChildBirthday = this.state.tax_reason === 'tax-exempt-small-kid' && this.state.tax_free ? this.is_valid_small_child(this.state.priv_birthday.value) : true;



        this.setState({
            priv_salutationError: !salutation,
            priv_firstNameError: !firstName,
            priv_lastNameError: !lastName,
            contact_emailError: !email,
            contact_emailFormatError: !emailFormat,
            contact_streetError: !street && !simpleRegistrationForm,
            contact_zipError: !zip && !simpleRegistrationForm,
            contact_cityError: !city && !simpleRegistrationForm,
            priv_nationalityError: !nationality && !simpleRegistrationForm,
            priv_languageError: !language && !simpleRegistrationForm,
            contact_countryError: !country && !simpleRegistrationForm,
            tax_reasonError: taxReason ? null : "Muss ausgefüllt sein",
            priv_birthdayError: !birthday && !simpleRegistrationForm,
            contact_mobileNumberError: !mobileNumber && !simpleRegistrationForm,
            contact_mobileNumberFormatError: !mobileNumberFormat && !simpleRegistrationForm,
            checkin_date_error: !checkinDate,
            exempt_child_birthday_error: !exemptChildBirthday && !simpleRegistrationForm,

        });

        if (simpleRegistrationForm) {
            return salutation && firstName && lastName && email && checkinDate
        } else {
            return salutation && firstName && birthday && lastName && email && emailFormat && street && zip && city && nationality && language && country && taxReason && mobileNumber && mobileNumberFormat && checkinDate && exemptChildBirthday;
        }
    }

    printPdf() {
        const requestURL = `${API_ROOT}admin/registration/forms/${this.props.currentRegistrationForm.id}/pdf`;
        window.open(requestURL);
    }

    setAnonymousState(value) {
        this.setState({
            anonymousGuest: value
        });
    }

    deleteGuest() {
        const registrationForm = this.props.currentRegistrationForm;
        this.props.deleteGuest(registrationForm.id, this.state.selectedGuest.id)
            .then((response) => {
                this.hideDeleteGuestPopup();
                toast.success("Successfully deleted.", {
                    bodyClassName: "toast-size",
                    toastClassName: "toast-success",
                });
                this.loadGuestById(this.primaryGuestId);
            });
    }

    showDeleteGuestPopup() {
        this.props.setDeleteGuestPopupVisible(true);
    }

    hideDeleteGuestPopup() {
        this.props.setDeleteGuestPopupVisible(false);
    }

    getInterestIds() {
        if (this.state.priv_interest && this.state.priv_interest.length > 0) {
            return this.state.priv_interest.map((obj) => {
                return obj.value;
            });
        } else {
            return [];
        }

    }

    deleteRegForm() {
        this.props.deleteRegForm(this.props.currentRegistrationForm.id).then(() => {
            this.props.history.push('/zterminal/registrationform');
        });
    }

    addNewGuestState() {
        this.clearForms(() => {
            this.setState({
                selectedGuest: null,
                isNewGuest: true,
                anonymousGuest: false,
                checkInDate: {key: 'checkInDate', value: Moment.utc(this.state.pass_checkinDate)},
                checkOutDate: {key: 'checkOutDate', value: Moment.utc(this.state.pass_checkoutDate)}
            });
        });
    }

    groupAdd(parents, kids, smallKids) {
        const json = {};
        json.kids = kids;
        json.adults = parents;
        json.smallKids = smallKids;

        this.props.addGroup(this.props.currentRegistrationForm.id, json).then(() => {
            this.loadGuestById(this.primaryGuestId);
        });
    }

    addExclusion(data) {
        this.props.addExclusion(this.props.match.params.id, data);
        $('#exclusionsModal').modal('hide');
    }

    updateExclusion(id, data) {
        this.props.updateExclusion(this.props.match.params.id, id, data);
        $('#exclusionsModal').modal('hide');
    }

    deleteExclusion(id) {
        this.props.deleteExclusion(this.props.match.params.id, id);
        $('#exclusionsModal').modal('hide');
    }

    updateRegistrationForm() {
        // on create
        if (this.props.create) {
            return Promise.resolve({});
        }

        const id = this.props.currentRegistrationForm.id;
        const data = {
            sendPostEmail: this.state.sendPostEmail,
            bookedPackage: this.state.bookedPackage
        };
        return this.props.updateRegistrationForm(id, data);
    }

    handleSubmit() {
        const updateStep = this.props.create ? Promise.resolve(true) : this.updateRegistrationForm();
        updateStep.then(ignored => this.doSubmit());
    }

    duplicate() {
        RegistrationFormService.duplicateRegistrationForm(this.props.currentRegistrationForm.id)
            .then((response) => {
                this.props.highlightForm(response?.newId);
                this.props.history.push('/zterminal/registrationform');
            });
    }

    doSubmit() {
        if (this.props.edit) {
            let isValid
            if (this.state.anonymousGuest) {
                const taxFreeValid = this.state.tax_free ?
                    this.is_valid_select(this.state.tax_reason) :
                    true;
                if (!taxFreeValid) {
                    this.setState({tax_reasonError: "Muss ausgefüllt sein"})
                }
                isValid = taxFreeValid;
            } else {
                isValid = this.validate();
            }

            if (isValid) {
                const guestBefore = this.state.selectedGuest;
                const currGuest = guestBefore ? guestBefore : this.props.currentRegistrationForm.guests.find(guest => guest.id === this.primaryGuestId);
                const guest = guestBefore ? guestBefore.guest : {};
                guest.checkinDate = {key: 'checkinDate', value: currGuest.checkinDate ? currGuest.checkinDate : null};
                guest.checkoutDate = {
                    key: 'checkoutDate',
                    value: currGuest.checkoutDate ? currGuest.checkoutDate : null
                };

                if (!guest.address) {
                    guest.address = {};
                }

                if (!guest.identificationDocument) {
                    guest.identificationDocument = {};
                }
                if (!guest.taxExemptReason) {
                    guest.taxExemptReason = {};
                }


                if (this.state.anonymousGuest) {
                    guest.taxCategoryId = this.state.tax_category;
                    guest.anonymous = true;
                    guest.comment = this.state.tax_comment;
                    guest.apartment = this.state.apartment;
                    guest.touristTaxExempt = this.state.tax_free;
                    guest.taxExemptReasonId = this.state.tax_reason;
                    guest.checkinDate = Moment.utc(this.state.pass_checkinDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
                    guest.checkoutDate = Moment.utc(this.state.pass_checkoutDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
                } else {

                    guest.salutation = this.state.priv_salutation;
                    guest.title = this.state.priv_title;
                    guest.firstName = this.state.priv_firstName;
                    guest.middleName = this.state.priv_middleName;
                    guest.lastName = this.state.priv_lastName;
                    guest.interestIds = this.getInterestIds();
                    guest.birthday = this.state.priv_birthday && this.state.priv_birthday?.value ? Moment.utc(this.state.priv_birthday.value).format('YYYY-MM-DD[T]HH:mm:ss') : null;
                    guest.email = this.state.contact_email;
                    guest.phoneNumber = this.state.contact_phoneNumber;
                    guest.mobileNumber = this.state.contact_mobileNumber;
                    guest.address.street = this.state.contact_street;
                    guest.address.zip = this.state.contact_zip;
                    guest.address.city = this.state.contact_city;
                    guest.address.country = this.state.contact_country;
                    guest.touristTaxExempt = this.state.tax_free;
                    guest.identificationDocument.number = this.state.pass_number;
                    guest.documentTypeId = this.state.pass_idCard;
                    guest.languageCode = this.state.priv_language;
                    guest.nationality = this.state.priv_nationality;
                    guest.company = this.state.priv_company;
                    guest.address.poBox = this.state.contact_post;
                    guest.comment = this.state.tax_comment;
                    guest.apartment = this.state.apartment;
                    guest.taxExemptReasonId = this.state.tax_reason;
                    guest.checkinDate = Moment.utc(this.state.pass_checkinDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
                    guest.checkoutDate = Moment.utc(this.state.pass_checkoutDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
                }

                const registrationForm = this.props.currentRegistrationForm;
                if (this.state.anonymousGuest) {
                    this.props.updateGuestDataRegForm(registrationForm.id, currGuest.id, guest).then((response) => {
                        toast.success("Updated successfully.", {
                            bodyClassName: "toast-size",
                            toastClassName: "toast-success",
                        });

                        this.loadGuestById(response.currentRegistrationForm.lastChangedGuestId);
                    });
                } else {
                    //if (this.validate()) {
                    if (this.state.isNewGuest && this.state.isNewGuest === true) {
                        this.props.addGuest(registrationForm.id, guest).then((response) => {
                            toast.success("Guest added successfully.", {
                                bodyClassName: "toast-size",
                                toastClassName: "toast-success",
                            });
                            this.loadGuestById(response.currentRegistrationForm.lastChangedGuestId);
                        });
                    } else {
                        this.props.updateGuestDataRegForm(registrationForm.id, currGuest.id, guest).then((response) => {
                            toast.success("Updated successfully.", {
                                bodyClassName: "toast-size",
                                toastClassName: "toast-success",
                            });
                            this.loadGuestById(response.currentRegistrationForm.lastChangedGuestId);
                        });
                    }

                    //}
                }
            }
        }

        if (this.props.create) {

            if (this.validate()) {

                const regForm = {};
                regForm.sendPostEmail = this.state.sendPostEmail;
                regForm.bookedPackage = this.state.bookedPackage;
                regForm.servicePartnerId = this.props.currentServicePartner.id;
                regForm.address = {};
                regForm.identificationDocument = {};

                regForm.salutation = this.state.priv_salutation;
                regForm.title = this.state.priv_title;
                regForm.firstName = this.state.priv_firstName;
                regForm.middleName = this.state.priv_middleName;
                regForm.lastName = this.state.priv_lastName;
                regForm.selectedInterests = this.state.priv_interest;
                regForm.birthday = this.state.priv_birthday.value ? Moment.utc(this.state.priv_birthday.value).format('YYYY-MM-DD[T]HH:mm:ss') : null;
                regForm.email = this.state.contact_email;
                regForm.phoneNumber = this.state.contact_phoneNumber;
                regForm.mobileNumber = this.state.contact_mobileNumber;
                regForm.address.street = this.state.contact_street;
                regForm.address.zip = this.state.contact_zip;
                regForm.address.city = this.state.contact_city;
                regForm.address.country = this.state.contact_country;
                regForm.documentTypeId = this.state.pass_idCard;
                regForm.documentType = this.state.pass_number;
                regForm.identificationDocument.number = this.state.pass_number;
                regForm.languageCode = this.state.priv_language;
                regForm.touristTaxExempt = this.state.tax_free;
                regForm.nationality = this.state.priv_nationality;
                regForm.company = this.state.priv_company;
                regForm.address.poBox = this.state.contact_post;
                regForm.interestIds = this.getInterestIds();
                regForm.comment = this.state.tax_comment;
                regForm.apartment = this.state.apartment;
                regForm.taxExemptReasonId = this.state.tax_reason;
                regForm.checkinDate = Moment.utc(this.state.pass_checkinDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
                regForm.checkoutDate = Moment.utc(this.state.pass_checkoutDate.value).format('YYYY-MM-DD[T]HH:mm:ss');

                this.props.createRegForm(regForm).then((response) => {
                    toast.success("Saved successfully.", {
                        bodyClassName: "toast-size",
                        toastClassName: "toast-success",
                    });

                    setTimeout(() => {
                        this.props.history.push(`/zterminal/registrationform/edit/${response.currentRegistrationForm.id}`);
                    }, 1000)

                }).catch((error) => {
                    console.log('Error Save Reg Form: ', error);
                    toast.error('Save unsuccessfully', {
                        bodyClassName: "toast-size",
                    });
                });

            } else {
                toast.warn("Daten müssen vervollständigt werden.", {
                    bodyClassName: "toast-size",
                });
            }
        }
    }

    render() {
        const sortedNationalityData = this.props.nationalityData.sort(function (a, b) {


            if (a.customOrder && b.customOrder) {
                return a.customOrder - b.customOrder;
            } else if (a.customOrder) {
                return -1;
            } else if (b.customOrder) {
                return 1;
            } else {
                if (a.label < b.label) {
                    return -1;
                } else if (a.label > b.label) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        const sortedInterestData = this.props.interestsData.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        const create = this.props.create;
        const edit = this.props.edit;
        const read = this.props.read;

        let isReadOnly = read;

        const registrationForm = this.props.currentRegistrationForm;

        let countMustTax = 0;
        let countMustNotTax = 0;

        if (registrationForm != null && !create) {
            if (registrationForm.taxStandaloneMode) {
                countMustTax = registrationForm.nonTaxFreeGuests
                countMustNotTax = registrationForm.taxFreeGuests
            } else {
                registrationForm.guests.forEach(guest => {
                    guest.touristTaxExempt === false ? countMustTax++ : countMustNotTax++;
                });
                isReadOnly = isReadOnly || registrationForm.tax === 'CHARGED';
            }
        } else {
            countMustTax = 0;
            countMustNotTax = 0;
        }

        return (
            <div className="col-12" id="registration-form-content">

                {
                    (!this.isPolice && !read) &&
                    <div className="d-flex container-fluid left p-0 header-wrap">
                    <div className="col-2" style={{ minWidth: '250px' }}>
                    </div>
                        <div className="content-header col-10 p-0">

                        <div className="header-row row container-fluid pl-3">
                            <div className="col-sm-3 col-lg-3 col-xl-6 col-xxl-4 kpi-box d-flex">

                                {
                                    !(registrationForm?.provider?.value === 'reconline' || registrationForm?.provider?.value === 'hotelpac') && <>
                                        <div className="header-element">
                                            <label className="heading-text" htmlFor="touristTaxExempt">
                                                <FormattedMessage
                                                    id='bonfire.containers.administration.RegistrationForm.detail.taxHeader'
                                                    defaultMessage='Kurtaxe Pflichtig'
                                                />
                                            </label>
                                            <span className="header-info">
                                                      {countMustTax}
                                                    </span>
                                        </div>

                                        <div className="header-element">
                                            <label className="heading-text" htmlFor="touristTaxExempt">
                                                <FormattedMessage
                                                    id='bonfire.containers.administration.RegistrationForm.detail.notTaxHeader'
                                                    defaultMessage='Von Kurtaxe befreit'
                                                />
                                            </label>
                                            <span className="header-info">
                                                    {countMustNotTax}
                                                </span>
                                        </div></>

                                }


                                <div className="header-element" style={{maxWidth: '250px'}}>
                                    <label className="heading-text" htmlFor="touristTaxExempt">
                                        <FormattedMessage
                                            id='bonfire.containers.administration.ServicePartner.list.bookingSource'
                                            defaultMessage='Buchungsquelle'
                                        />
                                    </label>
                                    <span className="header-info">
                                            {
                                                create ? (
                                                    '-'
                                                ) : (
                                                    registrationForm.bookingSource ? (
                                                        <span>{registrationForm.bookingSource} / {registrationForm.provider.value}</span>
                                                    ) : (
                                                        <span>{registrationForm.provider.value}</span>
                                                    )
                                                )
                                            }

                                        </span>
                                </div>


                            </div>

                            <div className="col-sm-12 col-lg-12 col-xl-6 col-xxl-4 kpi-box d-flex">
                                <div className="header-element">
                                    <label className="heading-text" htmlFor="touristTaxExempt">
                                        <FormattedMessage
                                            id='bonfire.containers.administration.RegistrationForm.detail.nightsHeader'
                                            defaultMessage='Nächte'
                                        />
                                    </label>
                                    <span className="header-info">
                                                    {create ? '-' : registrationForm.accomodationNightCount}
                                                </span>
                                </div>
                                <div className="header-element">
                                    <label className="heading-text" htmlFor="touristTaxExempt">
                                        <FormattedMessage
                                            id='bonfire.containers.administration.RegistrationForm.detail.countGuestsHeader'
                                            defaultMessage='Gäste'
                                        />
                                    </label>
                                    <span className="header-info">
                                                    {create ? '-' : registrationForm.guests.length}
                                                </span>
                                </div>
                                <div className="header-element">
                                    <label className="heading-text" htmlFor="touristTaxExempt">
                                        <FormattedMessage
                                            id='bonfire.containers.administration.RegistrationForm.detail.countTaxHeader'
                                            defaultMessage='Kurtaxe in CHF'
                                        />
                                    </label>
                                    <span className="header-info">
                                                    {create ? '-' : registrationForm.totalTax}
                                                </span>
                                </div>
                            </div>

                            {!create &&
                                <div className="col-sm-12 col-lg-12 col-xl-6 col-xxl-4 p-0 kpi-box d-flex">
                                    <div className="header-buttons">
                                        <div className="btn btn-secondary btn-120 mr-3" data-toggle="modal"
                                             data-target="#deleteRegistrationFormModal">
                                            <FormattedMessage
                                                id='bonfire.containers.administration.RegistrationForm.detail.deleteHeaderButton'
                                                defaultMessage='Löschen'
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    </div>


                }

                <div className="d-flex container-fluid left p-0 content-wrap">
                    <div className="col-2 fellow-traveler">
                        <div className="col-12 fellow-traveler-wrapper">
                              <span className="heading">
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.guests'
                                    defaultMessage='Gäste/Mitreisende'
                                />
                              </span>
                            {(this.state.guestList && this.state.guestList.length > 0) && this.state.guestList.map((object, key) =>
                                (<div key={key}
                                      className={'guest-card-element ' + (this.state.selectedGuest && this.state.selectedGuest.id === object.id ? 'selected' : '')}
                                      id={object.id} onClick={() => {
                                    this.loadGuestById(object.id)
                                }}>
                                    <div className="guest-infos-wrap">
                                        {
                                            (object.guest.lastName || object.guest.firstName) &&
                                            <div>
                                                <div
                                                    className="guest-text">{object.guest.lastName}, {object.guest.firstName}</div>
                                                <div
                                                    className="guest-text">{object.taxCategory && <FormattedMessage
                                                    id={object.touristTaxExempt === true ? "Kurtaxenbefreite Person" : object.taxCategory.label}
                                                    defaultMessage={object.taxCategory.label}
                                                />}</div>
                                            </div>}
                                        {
                                            (object.guest.lastName === null && object.guest.firstName === null && !object.touristTaxExempt === true) &&
                                            <span className="guest-text"><FormattedMessage
                                                id={object.taxCategory.label}
                                                defaultMessage='Translation not found'
                                            /></span>
                                        }
                                        {
                                            (object.guest.lastName === null && object.guest.firstName === null && object.touristTaxExempt === true) &&
                                            <span className="guest-text"><FormattedMessage
                                                id="Kurtaxenbefreite Person"
                                                defaultMessage='Translation not found'
                                            /></span>
                                        }
                                    </div>
                                </div>)
                            )}

                            {
                                this.state.isNewGuest &&

                                <div className='guest-card-element selected'>
                                    <div className="guest-infos-wrap">
                                        <span className="guestNameStyleRegForm">
                                            <FormattedMessage
                                                id='bonfire.containers.administration.ServicePartner.detail.newGuest'
                                                defaultMessage='Neuer Gast'
                                            />
                                        </span>
                                    </div>
                                </div>

                            }

                            {
                                edit &&

                                <div className='guest-card-element add'>
                                    <div className="guest-infos-wrap">
                                        <div className="button-add multi" data-toggle="modal" data-target="#addGroup">
                                            <FontAwesomeIcon icon={faUser} size={"md"} />
                                        </div>
                                        <div className="button-add single" onClick={this.addNewGuestState}>
                                            <FontAwesomeIcon icon={faPlus} size={"md"} />
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>

                    </div>
                    <div className="col-10 guest-information">
                        <div className="col-12">
                            {
                                (this.state.anonymousGuest && (edit || read)) ? (
                                    <div className="anonym row">

                                        <div className="col-lg-12 col-xl-6 col-xxl-4">
                                            <SobrFormGroup
                                                title={<FormattedMessage
                                                    id='bonfire.containers.administration.ServicePartner.detail.headlineRegformInfos'
                                                    defaultMessage='Meldescheininformationen'
                                                />}
                                            >

                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.taxCategory'
                                                        defaultMessage='Typ'
                                                    />}
                                                    inputClass={'react-select-container' + (this.state.touristTaxExemptError ? ' error' : '')}
                                                    inputClassPrefix="react-select"
                                                    id="anonym-taxCategory"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeTaxCategory}
                                                    name="selected-state"
                                                    options={this.props.taxCategoryData}
                                                    valueKey="id"
                                                    value={this.state.tax_category ? this.props.taxCategoryData.find((id) => id.value.toLowerCase() === this.state.tax_category.toLowerCase()) : ''}
                                                />

                                                <SobrDate
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.crdetaileate.arrival'
                                                        defaultMessage='Anreise'
                                                    />}
                                                    required={true}
                                                    value={this.state.pass_checkinDate}
                                                    onChange={this.onChangeCheckinDate}
                                                    disabled={isReadOnly}
                                                />

                                                <SobrDate
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.depature'
                                                        defaultMessage='Abreise'
                                                    />}
                                                    required={true}
                                                    value={this.state.pass_checkoutDate}
                                                    onChange={this.onChangeCheckoutDate}
                                                    disabled={isReadOnly}
                                                />

                                            </SobrFormGroup>
                                        </div>

                                        <div className="col-lg-12 col-xl-6 col-xxl-4">
                                            <SobrFormGroup
                                                title={<FormattedMessage
                                                    id='bonfire.containers.administration.RegistrationForm.detail.touristTaxExempt'
                                                    defaultMessage='Kurtaxe befreit?'
                                                />}
                                            >

                                                <SobrFormRow
                                                    label="bonfire.containers.administration.RegistrationForm.detail.touristTaxExempt">
                                                    <SobrCustomCheckbox
                                                        checked={this.state.tax_free}
                                                        onChange={this.updateState("tax_free")}
                                                        disabled={isReadOnly}
                                                    />
                                                </SobrFormRow>

                                                {this.state.tax_free &&

                                                <SobrFormRow
                                                    label="bonfire.containers.administration.RegistrationForm.detail.touristTaxExemptReason"
                                                    required={true}
                                                    error={this.state.tax_reasonError}
                                                >
                                                    <SobrSingleSelect
                                                        error={this.state.exempt_child_birthday_error}
                                                        readOnly={isReadOnly}
                                                        isClearable={false}
                                                        options={this.props.taxExemptReasonData}
                                                        value={this.state.tax_reason}
                                                        onChange={this.updateState("tax_reason")}
                                                    />
                                                </SobrFormRow>
                                                }

                                                <SobrFormRow
                                                    label="bonfire.containers.administration.RegistrationForm.detail.comment">
                                                    <SobrTextarea
                                                        value={this.state.tax_comment}
                                                        onChange={this.handleChange}
                                                        name="tax_comment"
                                                        readOnly={isReadOnly}
                                                    />
                                                </SobrFormRow>

                                                <SobrFormRow label="bonfire.containers.administration.RegistrationForm.detail.apartment">
                                                    <SobrTextInput
                                                        value={this.state.apartment}
                                                        onChange={this.handleChange}
                                                        name="apartment"
                                                        readOnly={isReadOnly}
                                                    />
                                                </SobrFormRow>

                                            </SobrFormGroup>
                                        </div>



                                    </div>
                                ) : (
                                    <div className="not-anonym row">

                                        <div className="col-lg-12 col-xl-6 col-xxl-4">
                                            <SobrFormGroup
                                                title={<FormattedMessage
                                                    id='bonfire.containers.administration.ServicePartner.list.headlineGuestInfos'
                                                    defaultMessage='Persönliche Daten'
                                                />}
                                            >


                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.company'
                                                        defaultMessage='Firma'
                                                    />}
                                                    id="company"
                                                    name="priv_company"
                                                    value={this.state.priv_company}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.salutation'
                                                        defaultMessage='Anrede'
                                                    />}
                                                    required={create || (this.state.selectedGuest && this.state.selectedGuest.primary)}
                                                    inputClass={this.state.priv_salutationError ? "form-error" : ""}
                                                    id="salutation"
                                                    name="priv_salutation"
                                                    value={this.state.priv_salutation}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.priv_salutationError}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.myTitle'
                                                        defaultMessage='Titel'
                                                    />}
                                                    id="title"
                                                    name="priv_title"
                                                    value={this.state.priv_title}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.firstName'
                                                        defaultMessage='Vorname'
                                                    />}
                                                    required={true}
                                                    inputClass={this.state.priv_firstNameError ? "form-error" : ""}
                                                    id="firstName"
                                                    name="priv_firstName"
                                                    value={this.state.priv_firstName}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.priv_firstNameError}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.middleName'
                                                        defaultMessage='Zweitname'
                                                    />}
                                                    id="middleName"
                                                    name="priv_middleName"
                                                    value={this.state.priv_middleName}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.lastName'
                                                        defaultMessage='Nachname'
                                                    />}
                                                    required={true}
                                                    inputClass={this.state.priv_lastNameError ? "form-error" : ""}
                                                    id="lastName"
                                                    name="priv_lastName"
                                                    value={this.state.priv_lastName}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.priv_lastNameError}
                                                />
                                                <SobrDate
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.birthdate'
                                                        defaultMessage='Geburtstag'
                                                    />}
                                                    required={!this.props.currentServicePartner.simpleRegistrationForm}
                                                    value={this.state.priv_birthday}
                                                    onChange={this.onChangeBirthday}
                                                    disabled={isReadOnly}
                                                    isError={this.state.priv_birthdayError}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    class={this.state.priv_birthdayError ? 'form-error' : ''}
                                                />

                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.nationality'
                                                        defaultMessage='Nationalität'
                                                    />}
                                                    required={!this.props.currentServicePartner.simpleRegistrationForm}
                                                    inputClass={'react-select-container' + (this.state.priv_nationalityError ? ' form-error' : '')}
                                                    inputClassPrefix="react-select"
                                                    id="nationality"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeNationality}
                                                    name="selected-state"
                                                    options={sortedNationalityData}
                                                    valueKey="code"
                                                    value={this.state.priv_nationality ? this.props.nationalityData.find((nat) => nat.value.toLowerCase() === this.state.priv_nationality.toLowerCase()) ? this.props.nationalityData.find((nat) => nat.value.toLowerCase() === this.state.priv_nationality.toLowerCase()) : this.props.nationalityData.find((nat) => nat.label.toLowerCase() === this.state.priv_nationality.toLowerCase()) : ''}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.priv_nationalityError}
                                                />

                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.languageCode'
                                                        defaultMessage='Sprache'
                                                    />}
                                                    required={true}
                                                    inputClass={'react-select-container' + (this.state.priv_languageError ? ' form-error' : '')}
                                                    inputClassPrefix="react-select"
                                                    id="languageCode"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeLanguageCode}
                                                    name="selected-state"
                                                    options={this.props.languageData}
                                                    valueKey="code"
                                                    value={this.state.priv_language ? this.props.languageData.find((lang) => lang.value.toLowerCase() === this.state.priv_language?.toLowerCase()) : ''}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.priv_languageError}
                                                />
                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.interests'
                                                        defaultMessage='Interessen'
                                                    />}
                                                    inputClass="react-select-container"
                                                    inputClassPrefix="react-select"
                                                    id="state-select"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeInterests}
                                                    name="selected-state"
                                                    options={sortedInterestData}
                                                    valueKey="id"
                                                    isMulti={true}
                                                    value={this.state.priv_interest ? this.state.priv_interest : ''}
                                                    closeMenuOnSelect={false}
                                                />

                                            </SobrFormGroup>
                                        </div>

                                        <div className="col-lg-12 col-xl-6 col-xxl-4">
                                            <SobrFormGroup
                                                title={<FormattedMessage
                                                    id='bonfire.containers.administration.ServicePartner.list.headlineContactInfos'
                                                    defaultMessage='Kontaktinformationen'
                                                />}
                                            >

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.email'
                                                        defaultMessage='Email'
                                                    />}
                                                    required={create || (this.state.selectedGuest && this.state.selectedGuest.primary)}
                                                    inputClass={(this.state.contact_emailError || this.state.contact_emailFormatError) ? "form-error" : ""}
                                                    id="email"
                                                    name="contact_email"
                                                    value={this.state.contact_email}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    emailSynthError={this.state.contact_emailFormatError}
                                                    emailSynthErrorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation2'
                                                        defaultMessage='Keine valide E-Mail Adresse'
                                                    />}
                                                    isError={this.state.emailError || this.state.contact_emailFormatError}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.phoneNumber'
                                                        defaultMessage='Telefonnummer'
                                                    />}
                                                    id="phoneNumber"
                                                    name="contact_phoneNumber"
                                                    value={this.state.contact_phoneNumber}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.mobileNumber'
                                                        defaultMessage='Mobilnummer'
                                                    />}
                                                    required={(create || (this.state.selectedGuest && this.state.selectedGuest.primary)) && !this.props.currentServicePartner.simpleRegistrationForm}
                                                    inputClass={(this.state.contact_mobileNumberError || this.state.contact_mobileNumberFormatError) ? "form-error" : ""}
                                                    id="mobileNumber"
                                                    name="contact_mobileNumber"
                                                    value={this.state.contact_mobileNumber}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    mobileNumberSynthError={this.state.contact_mobileNumberFormatError}
                                                    mobileNumberSynthErrorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation4'
                                                        defaultMessage='Keine gültige Mobilnummer'
                                                    />}
                                                    isError={this.state.contact_mobileNumberError || this.state.contact_mobileNumberFormatError}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.addressStreet'
                                                        defaultMessage='Strasse/Hausnr.'
                                                    />}
                                                    required={(create || (this.state.selectedGuest && this.state.selectedGuest.primary)) && !this.props.currentServicePartner.simpleRegistrationForm}
                                                    inputClass={this.state.contact_streetError ? "form-error" : ""}
                                                    id="guestAddressStreet"
                                                    name="contact_street"
                                                    value={this.state.contact_street}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.contact_streetError}
                                                />

                                                <SobrZipCity
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.adressPlc'
                                                        defaultMessage='PLZ/Ort'
                                                    />}
                                                    required={(create || (this.state.selectedGuest && this.state.selectedGuest.primary)) && !this.props.currentServicePartner.simpleRegistrationForm}
                                                    idZip="guestAddressZip"
                                                    nameZip="contact_zip"
                                                    inputClassZip={this.state.contact_zipError ? "form-error" : ""}
                                                    onChangeZip={this.handleChange}
                                                    valueZip={this.state.contact_zip}
                                                    idCity="guestAddressCity"
                                                    nameCity="contact_city"
                                                    readOnly={isReadOnly}
                                                    inputClassCity={this.state.contact_cityError ? "form-error" : ""}
                                                    onChangeCity={this.handleChange}
                                                    valueCity={this.state.contact_city}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.contact_zipError || this.state.contact_cityError}
                                                />

                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.addressCountry'
                                                        defaultMessage='Land'
                                                    />}
                                                    inputClass={'react-select-container' + (this.state.contact_countryError ? ' form-error' : '')}
                                                    inputClassPrefix="react-select"
                                                    id="state-select"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeCountry}
                                                    name="selected-state"
                                                    options={sortedNationalityData}
                                                    valueKey="code"
                                                    value={this.state.contact_country ? this.props.nationalityData.find((country) => country.value.toLowerCase() === this.state.contact_country.toLowerCase()) ? this.props.nationalityData.find((country) => country.value.toLowerCase() === this.state.contact_country.toLowerCase()) : this.props.nationalityData.find((country) => country.label.toLowerCase() === this.state.contact_country.toLowerCase()) : ''}
                                                    required={(create || (this.state.selectedGuest && this.state.selectedGuest.primary)) && !this.props.currentServicePartner.simpleRegistrationForm}
                                                    errorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation1'
                                                        defaultMessage='Muss ausgefüllt sein'
                                                    />}
                                                    isError={this.state.contact_countryError}
                                                />

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.poBox'
                                                        defaultMessage='Postfach'
                                                    />}
                                                    id="poBox"
                                                    name="contact_post"
                                                    value={this.state.contact_post}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                            </SobrFormGroup>
                                        </div>


                                        <div className="col-lg-12 col-xl-6 col-xxl-4">
                                            <SobrFormGroup
                                                title={<FormattedMessage
                                                    id='bonfire.containers.administration.ServicePartner.list.headlinePassportInfos'
                                                />}
                                            >

                                                <SobrText
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.passportNumber'
                                                        defaultMessage='Ausweisnummer'
                                                    />}
                                                    id="identificationDocumentPassportNumber"
                                                    name="pass_number"
                                                    value={this.state.pass_number}
                                                    onChange={this.handleChange}
                                                    readOnly={isReadOnly}
                                                />

                                                <SobrLegacySelect
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.documentType'
                                                        defaultMessage='Ausweistyp'
                                                    />}
                                                    inputClass={'react-select-container'}
                                                    inputClassPrefix="react-select"
                                                    id="touristCategory"
                                                    placeholder={<FormattedMessage
                                                        id='bonfire.containers.choose'
                                                        defaultMessage='Auswählen'
                                                    />}
                                                    disabled={isReadOnly}
                                                    clearable={false}
                                                    onChange={this.onChangeDocumentType}
                                                    name="selected-state"
                                                    options={this.props.idCardData}
                                                    valueKey="id"
                                                    value={this.state.pass_idCard ? this.props.idCardData.find((id) => id.value.toLowerCase() === this.state.pass_idCard) : ''}
                                                />

                                                <SobrDate
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.crdetaileate.arrival'
                                                        defaultMessage='Anreise'
                                                    />}
                                                    required={true}
                                                    checkinDateSynthError={this.state.checkin_date_error}
                                                    checkinDateSynthErrorMessage={<FormattedMessage
                                                        id='bonfire.containers.validation5'
                                                        defaultMessage='SVR'
                                                    />}
                                                    class={this.state.checkin_date_error ? 'form-error' : ''}
                                                    isError={this.state.checkin_date_error}
                                                    value={this.state.pass_checkinDate}
                                                    onChange={this.onChangeCheckinDate}
                                                    disabled={isReadOnly}
                                                />

                                                <SobrDate
                                                    label={<FormattedMessage
                                                        id='bonfire.containers.administration.RegistrationForm.detail.depature'
                                                        defaultMessage='Abreise'
                                                    />}
                                                    required={true}
                                                    value={this.state.pass_checkoutDate}
                                                    onChange={this.onChangeCheckoutDate}
                                                    disabled={isReadOnly}
                                                />

                                            </SobrFormGroup>
                                        </div>


                                        {
                                            !this.isPolice &&
                                            <div className="col-lg-12 col-xl-6 col-xxl-4">
                                                <SobrFormGroup
                                                    title={<FormattedMessage
                                                        id='bonfire.containers.administration.ServicePartner.list.headlineTaxInfos'
                                                        defaultMessage='Kurtaxe'
                                                    />}
                                                >

                                                    <SobrFormRow
                                                        label="bonfire.containers.administration.RegistrationForm.detail.touristTaxExempt">
                                                        <SobrCustomCheckbox
                                                            checked={this.state.tax_free}
                                                            onChange={this.updateState("tax_free")}
                                                            disabled={isReadOnly}
                                                        />
                                                    </SobrFormRow>

                                                    {this.state.tax_free &&
                                                    <SobrFormRow
                                                        label="bonfire.containers.administration.RegistrationForm.detail.touristTaxExemptReason"
                                                        required={true}
                                                        error={this.state.tax_reasonError}
                                                    >
                                                        <SobrSingleSelect
                                                            readOnly={isReadOnly}
                                                            isClearable={false}
                                                            options={this.props.taxExemptReasonData}
                                                            value={this.state.tax_reason}
                                                            onChange={this.updateState("tax_reason")}
                                                        />
                                                        {this.state.exempt_child_birthday_error && <div className="errorTextStyle"><FormattedMessage
                                                            id='bonfire.containers.validation6'
                                                            defaultMessage='Der ausgewählte Gast ist älter als 9 Jahre'
                                                        /></div>}
                                                    </SobrFormRow>
                                                    }

                                                    <SobrFormRow label="bonfire.containers.administration.RegistrationForm.detail.comment">
                                                        <SobrTextarea
                                                            value={this.state.tax_comment}
                                                            onChange={this.handleChange}
                                                            name="tax_comment"
                                                            readOnly={isReadOnly}
                                                        />
                                                    </SobrFormRow>

                                                    <SobrFormRow label="bonfire.containers.administration.RegistrationForm.detail.apartment">
                                                        <SobrTextInput
                                                            value={this.state.apartment}
                                                            onChange={this.handleChange}
                                                            name="apartment"
                                                            readOnly={isReadOnly}
                                                        />
                                                    </SobrFormRow>
                                                </SobrFormGroup>
                                            </div>
                                        }

                                    </div>
                                )

                            }

                        </div>
                    </div>
                </div>
                <SobrPopup show={this.props.deleteGuestPopupVisible}
                           title={<FormattedMessage id="bonfire.containers.administration.RegistrationForm.detail.removeGuest"
                                                    defaultMessage="Translation missing"/>}
                           onClose={this.hideDeleteGuestPopup}
                >
                    <div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <span className="icon icon-delete" style={{"fontSize": "96px"}}/>
                            </div>
                        </div>
                        <div className="row mt-3 text-center">
                            <div className="col-12">
                                Wollen Sie den Gast wirklich entfernen?
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6 text-left">
                                <button className="btn btn-secondary btn-160"
                                        onClick={this.hideDeleteGuestPopup}>
                                    Abbrechen
                                </button>
                            </div>
                            <div className="col-6 text-right">
                                <button className="btn btn-primary btn-160"
                                        onClick={this.deleteGuest}>
                                    Entfernen
                                </button>
                            </div>
                        </div>
                    </div>
                </SobrPopup>
                <DialogComponent
                    style="addGroup"
                    id="addGroup"
                    title={{
                        id: 'bonfire.containers.SelfServiceStep5Page.title',
                        defaultMessage: 'Mitreisende'
                    }}
                     message="Quick Edit Mode"
                     actionButton={{id: 'bonfire.containers.groupBooking.6', defaultMessage: 'Hinzufügen'}}
                     parents={{
                         id: 'bonfire.containers.administration.TouristTax.parentsHeader',
                         defaultMessage: 'Erwachsene'
                     }}
                    childrens={{
                        id: 'bonfire.containers.administration.TouristTax.childrenHeader',
                        defaultMessage: 'Kinder'
                    }}
                    cancelButton={{
                        id: 'bonfire.containers.administration.ClosingDays.cancelButton',
                        defaultMessage: 'Abbrechen'
                    }}
                    subchildrens={{id: 'bonfire.containers.groupBooking.4', defaultMessage: 'Kleinkinder'}}
                    function={this.groupAdd}
                />
                <DialogComponent
                    style="deleteNew" id="deleteRegistrationFormModal"
                    title={{
                        id: 'bonfire.containers.administration.RegistrationForm.detail.deleteRegFormTitle',
                        defaultMessage: 'Meldeschein löschen?'
                    }}
                    message={{
                        id: 'bonfire.containers.administration.RegistrationForm.detail.deleteRegFormConent',
                        defaultMessage: 'Möchten Sie den Meldeschein wirklich löschen?'
                    }}
                    actionButton={{
                        id: 'bonfire.containers.administration.RegistrationForm.detail.deleteRegFormSaveButton',
                        defaultMessage: 'Löschen'
                    }}
                    cancelButton={{
                        id: 'bonfire.containers.administration.RegistrationForm.detail.deleteRegFormCancelButton',
                        defaultMessage: 'Abbrechen'
                    }}
                    function={this.deleteRegForm}
                />
                {
                    !create &&
                    <DialogComponent
                        style="exclusions"
                        id="exclusionsModal"
                        title="Meldeschein vervollständigen?"
                        message="Ist der Meldeschein wirklich vollständig?"
                        actionButton="Speichern"
                        function={this.addExclusion}
                        function2={this.deleteExclusion}
                        function3={this.updateExclusion}
                        data={registrationForm ? registrationForm.exclusionPeriods : []}
                        taxCategories={this.props.taxCategories}
                    />

                }

                <DialogComponent
                    style="pmsInfo"
                    id="pmsInfo"
                    title="PMS Info"
                    message={this.state.infoText}
                />
                <CustomToastContainer/>

                <div className="footer-area py-2">
                    <div className="row w-100">

                        {/* all the footer buttons */}
                        <div className="col-8">

                            {/* delete guest */}
                            {this.canDeleteCurrentGuest &&
                            <button className="btn btn-secondary btn-160 mr-3" onClick={this.showDeleteGuestPopup}>
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.detail.removeGuest'
                                    defaultMessage='Missing translation'
                                />
                            </button>
                            }

                            {/* de-anonymize */}
                            {this.canDeanonymizeCurrentGuest &&
                            <button className="btn btn-secondary btn-160 mr-3"
                                    onClick={() => this.setAnonymousState(false)}>
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.detail.deAonymisieren'
                                    defaultMessage='De-Anonymisieren'
                                />
                            </button>
                            }

                            {/* anonymize */}
                            {this.canAnonymizeCurrentGuest &&
                            <button className="btn btn-secondary btn-160 mr-3"
                                    onClick={() => this.setAnonymousState(true)}>
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.detail.aonymisieren'
                                    defaultMessage='Anonymisieren'
                                />
                            </button>
                            }

                            {/* PDF */}
                            {!create &&
                            <button className="btn btn-secondary btn-160 mr-3" onClick={() => this.printPdf()}>
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.detail.pdf'
                                    defaultMessage='PDF'
                                />
                            </button>
                            }

                            {/* back button */}
                            {this.showBackButton &&
                            <Link to="/zterminal/registrationform" className="btn btn-secondary btn-160 mr-3">
                                <FormattedMessage
                                    id='bonfire.containers.administration.ServicePartner.list.backButton'
                                    defaultMessage='Zurück'
                                />
                            </Link>
                            }

                            {/* duplicate */}
                            {this.canDuplicate &&
                                <button className="btn btn-secondary btn-160 mr-3"
                                        onClick={this.duplicate}>
                                    <FormattedMessage
                                        id='bonfire.containers.administration.RegistrationForm.detail.duplicate'
                                        defaultMessage='Duplizieren'
                                    />
                                </button>
                            }

                            {/* save */}
                            {this.canSaveCurrentGuest &&
                            <button className="btn btn-primary btn-160 mr-3" onClick={this.handleSubmit}>
                                <FormattedMessage
                                    id='bonfire.containers.administration.RegistrationForm.detail.saveButton'
                                    defaultMessage='Speichern'
                                />
                            </button>
                            }
                        </div>

                        {/* all the footer flags */}
                        <div className="col-4">
                            <form className="form-inline" id="registration-form-flags">
                                <div className="form-check mr-4">
                                    <SobrCustomCheckbox
                                        id="sendPostEmail"
                                        name="sendPostEmail"
                                        checked={this.state.sendPostEmail}
                                        onChange={this.updateState("sendPostEmail")}
                                        disabled={isReadOnly}
                                    />
                                    <label className="form-check-label" htmlFor="sendPostEmail">
                                        <FormattedMessage
                                            id="bonfire.containers.administration.RegistrationForm.detail.sendPostEmail"
                                            defaultMessage="Emails versenden"/>
                                    </label>
                                </div>
                                <div className="form-check mr-4">
                                    <SobrCustomCheckbox
                                        id="bookedPackage"
                                        name="bookedPackage"
                                        checked={this.state.bookedPackage}
                                        onChange={this.updateState("bookedPackage")}
                                        disabled={isReadOnly}
                                    />
                                    <label className="form-check-label" htmlFor="bookedPackage">
                                        <FormattedMessage
                                            id="bonfire.containers.administration.RegistrationForm.detail.bookedPackage"
                                            defaultMessage="Paket gebucht"/>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>

        );
    }
}

export const RegistrationForm = compose(
    withRouter,
    connect(
        state => ({
            userType: SessionSelectors.userType(state),
            currentRegistrationForm: state.getIn(['zterminal', 'registrationForm', 'currentRegistrationForm']),
            nationalityData: state.getIn(['zterminal', 'registrationForm', 'nationalityData']),
            interestsData: state.getIn(['zterminal', 'registrationForm', 'interestsData']),
            languageData: state.getIn(['zterminal', 'registrationForm', 'languageData']),
            idCardData: state.getIn(['zterminal', 'registrationForm', 'idCardData']),
            taxCategoryData: state.getIn(['zterminal', 'registrationForm', 'taxCategoryData']),
            taxExemptReasonData: state.getIn(['zterminal', 'registrationForm', 'taxExemptReasonData']),
            currentServicePartner: SessionSelectors.currentServicePartner(state),
            deleteGuestPopupVisible: state.getIn(['zterminal', 'registrationForm', 'deleteGuestPopupVisible'])
        }),
        (dispatch) => ({
            createRegForm: data => dispatch(Actions.createRegForm(data)),
            updateRegistrationForm: (id, data) => dispatch(Actions.updateRegistrationForm(id, data)),
            deleteGuest: (regForm, guestId) => dispatch(Actions.deleteGuest(regForm, guestId)),
            deleteRegForm: regFormId => dispatch(Actions.deleteRegForm(regFormId)),
            addGroup: (id, data) => dispatch(Actions.addGroup(id, data)),
            updateGuestDataRegForm: (regFormId, guestId, data) => dispatch(Actions.updateGuestDataRegForm(regFormId, guestId, data)),
            addExclusion: (id, data) => dispatch(Actions.addExclusion(id, data)),
            updateExclusion: (id, exId, data) => dispatch(Actions.updateExclusion(id, exId, data)),
            deleteExclusion: (id, exId) => dispatch(Actions.deleteExclusion(id, exId)),
            addGuest: (id, data) => dispatch(Actions.addGuest(id, data)),
            setDeleteGuestPopupVisible: (visible) => dispatch(Actions.setDeleteGuestPopupVisible(visible)),
            highlightForm: (id) => dispatch(RegistrationFormListActions.setHighlightedRegistrationFormId(id))
        })
    ))(injectIntl(RegistrationFormComponent));

