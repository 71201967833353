import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {SessionActions, SessionSelectors} from "./SessionSlice";
import * as R from "ramda";
import {SobrFormRow} from "components/SobrForm/Forms/SobrFormRow";
import SobrTextInput from "components/SobrForm/Inputs/SobrTextInput";
import SobrPasswordInput from "components/SobrForm/Inputs/SobrPasswordInput";

import "./login.scss";
import {SobrFormGroup} from "components/SobrForm/SobrFormGroup";
import CustomToastContainer from "components/CustomToastContainer";
import logo from 'images/logos/zermattLogoBig.png';
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";

class LoginComponent extends React.PureComponent {

    static propTypes = {
        loggedIn: PropTypes.bool,
        onLogin: PropTypes.func,
        loading: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
        };

        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChangeUsername(value) {
        this.setState({username: value});
    }

    onChangePassword(value) {
        this.setState({password: value});
    }

    handleSubmit(e) {
        if (R.is(Function, this.props.onLogin)) {
            this.props.onLogin({
                username: this.state.username,
                password: this.state.password
            })
                .catch(error => {
                    const errorMsg = this.getErrorMessage(error);
                    toast.error(errorMsg, {
                        bodyClassName: 'toast-size'
                    })
                });
        }
        e.preventDefault();
    }

    getErrorMessage(caughtError) {
        switch (caughtError) {
            case 'bad-credentials':
                return "E-Mail Adresse oder Passwort nicht korrekt.";
            case 'user-locked':
                return "Benutzerkonto ist vorläufig gesperrt.";
            case undefined:
                return "Server antwortet nicht.";
            default:
                return `Fehler: ${caughtError}`;
        }
    }

    render() {
        return (
            <LoadingOverlay loading={this.props.loading}>
            <div id="login-page">
                <Helmet>
                    <title>Login</title>
                </Helmet>

                <div className="login-box">

                    <div className="login-logo">
                        <img alt="Logo" className="login-logo-img" src={logo}/>
                    </div>

                    <form className="login-form" onSubmit={this.handleSubmit}>
                        <SobrFormGroup>
                            <SobrFormRow label="bonfire.containers.Login.username">
                                <SobrTextInput value={this.state.username}
                                               onValueChange={this.onChangeUsername}
                                />
                            </SobrFormRow>

                            <SobrFormRow label="bonfire.containers.Login.password">
                                <SobrPasswordInput value={this.state.password}
                                                   onChange={this.onChangePassword}
                                />
                            </SobrFormRow>
                        </SobrFormGroup>

                        <div className="login-form-buttons text-center mt-3">
                            <div>
                                <button type="submit" className="btn btn-primary btn-300 mt-3">
                                    <FormattedMessage
                                        id="bonfire.containers.Login.loginButton"
                                        defaultMessage="Login"/>
                                </button>
                            </div>

                            <div>
                                <Link to="/forgot_password" className="btn btn-secondary btn-300 mt-3">
                                    Passwort vergessen?
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>

                <CustomToastContainer/>
            </div>
            </LoadingOverlay>
        );
    }
}


export const Login = connect(
    state => {
        return {
            loggedIn: SessionSelectors.loggedIn(state),
            loading: SessionSelectors.sessionLoading(state),
        }
    },

    dispatch => ({
        onLogin: credentials => {
            return dispatch(SessionActions.login(credentials));
        }
    })
)(LoginComponent);
