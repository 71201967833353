import * as R from "ramda";
import PropTypes from "prop-types";
import v from "lib/validation";
import f from "lib/util";
import userService from "services/users/UserService";

export const USER_DATA_PROPTYPE = PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    partnerGroupId: PropTypes.string,
    servicePartnerId: PropTypes.string,
    roleIds: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    passwordExpired: PropTypes.bool,
    admin: PropTypes.bool,
    generatePassword: PropTypes.bool
});

export const VALIDATION_DATA_PROPTYPE = PropTypes.shape({
    firstName: v.VALIDATION_RESULT_PROPTYPE,
    lastName: v.VALIDATION_RESULT_PROPTYPE,
    email: v.VALIDATION_RESULT_PROPTYPE,
    partnerGroupId: v.VALIDATION_RESULT_PROPTYPE,
    servicePartnerId: v.VALIDATION_RESULT_PROPTYPE,
    roleIds: v.VALIDATION_RESULT_PROPTYPE,
    password: v.VALIDATION_RESULT_PROPTYPE,
    passwordConfirmation: v.VALIDATION_RESULT_PROPTYPE,
    passwordExpired: v.VALIDATION_RESULT_PROPTYPE
});

export const BASE_DATA_PROPTYPE = PropTypes.shape({
    partnerGroups: PropTypes.array.isRequired,
    servicePartners: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired
});

export function userToFormData(user, roleAssignmentMode, passwordMode) {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        partnerGroupId: R.path(['partnerGroup', 'id'], user),
        servicePartnerId: R.path(['servicePartner', 'id'], user),
        ...(roleAssignmentMode === 'roles' && {roleIds: R.map(R.prop('id'), user.roles)}),
        ...(roleAssignmentMode === 'isAdmin' && {admin: false}),
        password: '',
        passwordConfirmation: '',
        ...(passwordMode === 'expire' && {passwordExpired: user.passwordExpired}),
        ...(passwordMode === 'generate' && {generatePassword: true})
    };
}

export function emptyUserFormData(roleAssignmentMode, passwordMode) {
    return {
        firstName: '',
        lastName: '',
        email: '',
        partnerGroupId: null,
        servicePartnerId: null,
        ...(roleAssignmentMode === 'roles' && {roleIds: []}),
        ...(roleAssignmentMode === 'isAdmin' && {admin: false}),
        password: '',
        passwordConfirmation: '',
        ...(passwordMode === 'expire' && {passwordExpired: false}),
        ...(passwordMode === 'generate' && {generatePassword: true})
    };
}

export function validateUserFormData(userData, baseData, roleAssignmentMode) {
    const validServicePartnerIds = R.map(R.prop('value'), baseData.servicePartners);
    const validPartnerGroupIds = R.map(R.prop('value'), baseData.partnerGroups);
    const validRoleIds = R.map(R.prop('value'), baseData.roles);

    return v.createValidator({
        firstName: v.requiredStandardTextField,
        lastName: v.requiredStandardTextField,
        email: v.required(v.and([
            v.lengthRange(1, 255),
            v.isEmail
        ])),
        partnerGroupId: (value, dataObject) => {
            if (!userService.isServicePartnerUser(dataObject.roleIds)) {
                return true;
            } else if (f.isNotNilOrEmpty(dataObject.servicePartnerId)) {
                return v.withMessage("Servicepartner und Partnergruppe dürfen nicht beide gesetzt sein", f.isNilOrEmpty)(value, dataObject);
            } else {
                return v.and([
                    v.withMessage("Entweder Servicepartner oder PartnerGruppe muss ausgewählt sein", f.hasValue),
                    v.withMessage("Keine gültige Partnergrupp", f.includedIn(validPartnerGroupIds))
                ])(value, dataObject);
            }
        },
        servicePartnerId: (value, dataObject) => {
            if (!userService.isServicePartnerUser(dataObject.roleIds)) {
                return true;
            } else if (f.isNotNilOrEmpty(dataObject.partnerGroupId)) {
                return v.withMessage("Servicepartner und Partnergruppe dürfen nicht beide gesetzt sein", f.isNilOrEmpty)(value, dataObject);
            } else {
                return v.and([
                    v.withMessage("Entweder Servicepartner oder PartnerGruppe muss ausgewählt sein", f.hasValue),
                    v.withMessage("Kein gültiger Service Partner", f.includedIn(validServicePartnerIds))
                ])(value, dataObject);
            }
        },
        ...(roleAssignmentMode === 'roles' && {
            roleIds: v.and([
                R.is(Array),
                v.withMessage('Mindestens eine Berechtigung muss ausgewählt sein', v.notEmpty),
                R.all(R.is(String)),
                v.withMessage('Keine gültige Rolle', R.all(f.includedIn(validRoleIds)))
            ])
        }),
        password: v.optional(v.validPassword),
        passwordConfirmation: v.withMessage('Stimmt nicht überein',
            (value, dataObject) => dataObject['password'] === value)
    })(userData);
}

export default {
    USER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE,
    BASE_DATA_PROPTYPE,
    userToFormData,
    emptyUserFormData,
    validateUserFormData
}
