import ApiService from "../api/ApiService";
import CredentialStore from "./CredentialStore";

const loginRequest = ApiService.createApiEndpoint('token', 'POST', false);
function login(username, password) {
    return loginRequest({username, password})
        .then(loginData => {
            CredentialStore.setData(loginData);
            return loginData;
        })
        .catch(response => {
            if (response.status === 400 || response.status === 401) {
                return Promise.reject("bad-credentials");
            }
            if (response.status === 429) {
                return Promise.reject("user-locked");
            }
            return Promise.reject(response.status);
        });
}

function logout() {
    CredentialStore.remove();
    return Promise.resolve();
}

const refreshTokenRequest = ApiService.createApiEndpoint('oauth/token', 'GET', false);
function refreshToken() {
    const loginData = CredentialStore.getData();
    if (!loginData) {
        return Promise.reject('no-refresh-token');
    }

    return refreshTokenRequest({
        grant_type: 'refresh_token',
        refresh_token: loginData.refresh_token
    })
        .then(loginData => {
            CredentialStore.setData(loginData);
            return loginData;
        })
        .catch(error => {
            logout();
            return Promise.reject(error);
        });
}

export default {
    login, refreshToken, logout
}
